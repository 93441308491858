<template>
  <div class="property-change-price card p-4">
    <div class="ref-code">{{ property.referenceCode }}</div>
    <fd-form
      @submit="$emit('update', { id: property.id, priceData: priceData })"
    >
      <fd-form-section title="Change Price">
        <fd-input
          v-model="priceData.salePrice"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Sale Price"
          name="salePrice"
          type="text"
          :validators="[validator.required, validator.price]"
        >
        </fd-input>
        <fd-input
          v-model="priceData.salePricePerUnit"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Sale Price Per Unit"
          name="salePricePerUnit"
          type="text"
          :validators="[validator.required, validator.price]"
        >
        </fd-input>
        <fd-input
          v-model="priceData.rentPrice"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Rent Price"
          name="rentPrice"
          type="text"
          :validators="[validator.required, validator.price]"
        >
        </fd-input>
        <fd-input
          v-model="priceData.rentPricePerUnit"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Rent Price Per Unit"
          name="rentPricePerUnit"
          type="text"
          :validators="[validator.required, validator.price]"
        >
        </fd-input>
      </fd-form-section>
      <div class="text-right">
        <button class="btn mr-1" @click="$emit('close')">Cancel</button>
        <button type="submit" class="btn main">Update</button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    property: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      priceData: {
        salePrice: "",
        salePricePerUnit: "",
        rentPrice: "",
        rentPricePerUnit: ""
      },

      validator: {
        required: required,
        price: price
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initPriceData();
  },
  methods: {
    initPriceData() {
      this.priceData.salePricePerUnit = this.property.salePricePerUnit;
      this.priceData.salePrice = this.property.salePrice;
      this.priceData.rentPricePerUnit = this.property.rentPricePerUnit;
      this.priceData.rentPrice = this.property.rentPrice;
    }
  }
};
</script>

<style lang="scss">
.ref-code {
  margin-bottom: 12px;
  border: solid 1px $color-secondary;
  width: fit-content;
  border-radius: 5px;
  padding: 6px 15px;
  color: $color-secondary;
}
</style>
